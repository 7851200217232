import React from "react";
import ReactDOM from "react-dom/client"; // Import ReactDOM for creating root
import { Provider } from "react-redux";
import App from "./App";
import { storePromise } from "./app/store";
import { BrowserRouter } from "react-router-dom";

storePromise.then((store) => {
   const root = ReactDOM.createRoot(document.getElementById("root"));
   root.render(
      <Provider store={store}>
         <BrowserRouter>
            <App />
         </BrowserRouter>
      </Provider>
   );
});
