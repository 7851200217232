import React from "react";
import { Paper, Typography, Button, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAppSelector } from "../app/hooks";
import { SCHOOL_LOGO } from "../components/constants";

function PayPage({ total, setPayPage }) {
   const session = useAppSelector((state) => state.session);
   const EN = session.lang === "en" ? true : false;
   return (
      <Box
         sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
         }}
      >
         <Paper
            elevation={2}
            sx={{
               padding: "20px",
               width: "400px",
               textAlign: "center",
            }}
         >
            <img
               src={"./img/" + SCHOOL_LOGO}
               alt='Logo'
               style={{ width: "200px" }}
            />
            <Typography variant='h6' gutterBottom>
               Total ${total.toFixed(2)}
            </Typography>
            <Button
               variant='contained'
               color='primary'
               sx={{ marginTop: "15px", width: "100%" }}
            >
               {EN ? "Pay with Yappy" : "Pagar con Yappy"}
            </Button>
            <Button
               variant='contained'
               color='secondary'
               sx={{ marginTop: "15px", width: "100%" }}
            >
               {EN ? "Pay with Card" : "Pagar con Tarjeta"}
            </Button>
            <Button
               sx={{ marginTop: "15px" }}
               startIcon={<ArrowBackIcon />}
               onClick={() => setPayPage(false)}
            >
               {EN ? "Back" : "Volver"}
            </Button>
         </Paper>
      </Box>
   );
}

export default PayPage;
