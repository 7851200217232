import React from "react";
import {
   Paper,
   Typography,
   TextField,
   Button,
   Box,
   Snackbar,
   Alert,
} from "@mui/material";
import { SCHOOL_LOGO } from "../components/constants";
import { cleanUrl } from "../utils/general";
import { useAppSelector } from "../app/hooks";

function EmailPage({
   setEmail,
   email,
   snackbarOpen,
   handleSnackbarClose,
   setEmailPage,
   setSnackbarOpen,
}) {
   const session = useAppSelector((state) => state.session);
   const EN = session.lang === "en" ? true : false;
   const handleEmailValidation = () => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const url = cleanUrl(window.location.href);
      if (!emailRegex.test(email)) {
         setSnackbarOpen(true);
         // fetch api call to log the email

         fetch("https://dev.paytiptap.com/api/logs/add", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email, url, success: false }),
         });
      } else {
         fetch("https://dev.paytiptap.com/api/logs/add", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email, url, success: true }),
         });
         setEmailPage(false);
      }
   };

   return (
      <>
         <Box
            sx={{
               display: "flex",
               justifyContent: "center",
               marginTop: "10vh",
               alignItems: "center",
               flexDirection: "column",
               padding: "5px",
               width: "100%",
               boxSizing: "border-box",
            }}
         >
            <Box
               sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 2,
                  width: "100%",
                  maxWidth: "calc(100% - 10px)",
               }}
            >
               <img
                  src={"./img/" + SCHOOL_LOGO}
                  alt='Logo 1'
                  style={{ height: "80px", marginRight: "10px" }}
               />
               <img
                  src='./logott.webp'
                  alt='Logo 2'
                  style={{ height: "60px", marginLeft: "10px" }}
               />
            </Box>
            <Typography
               sx={{
                  marginTop: "10px",
                  fontSize: "1.5rem",
                  textAlign: "center",
                  fontWeight: "bold",
               }}
            >
               {EN ? "PAYMENT PLATFORM" : "PLATAFORMA DE PAGO"}
            </Typography>
            <Paper
               elevation={2}
               sx={{
                  padding: "20px",
                  width: "100%",
                  maxWidth: "400px",
                  textAlign: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: 2,
                  boxSizing: "border-box",
               }}
            >
               <Typography variant='h6' gutterBottom>
                  {EN ? "Enter your email" : "Ingresa tu correo"}
               </Typography>
               <TextField
                  label={EN ? "Email" : "Correo"}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  variant='outlined'
               />
               <Button
                  variant='contained'
                  color='primary'
                  sx={{ marginTop: "15px", width: "100%" }}
                  onClick={handleEmailValidation}
               >
                  {EN ? "Continue" : "Continuar"}
               </Button>
            </Paper>
            <Snackbar
               open={snackbarOpen}
               autoHideDuration={6000}
               onClose={handleSnackbarClose}
            >
               <Alert onClose={handleSnackbarClose} severity='error'>
                  {EN ? "Invalid email" : "Correo inválido"}
               </Alert>
            </Snackbar>
         </Box>
      </>
   );
}

export default EmailPage;
