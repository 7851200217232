import React from "react";

import {
   Paper,
   Table,
   TableHead,
   TableRow,
   TableCell,
   TableBody,
} from "@mui/material";

const HistoryExample = (props) => {
   const { paymentHistory } = props;
   return (
      <Paper
         elevation={2}
         style={{
            padding: "20px",
            marginBottom: "20px",
            overflowX: "auto", // Enable horizontal scrolling
            maxWidth: "100%", // Ensure Paper doesn't overflow its container
            boxSizing: "border-box", // Include padding in width calculations
         }}
      >
         <Table style={{ minWidth: "500px" }}>
            {" "}
            {/* Set a minimum width to ensure the table scrolls when needed */}
            <TableHead>
               <TableRow>
                  <TableCell>Fecha de Pago</TableCell>
                  <TableCell>Estudiante</TableCell>
                  <TableCell>Descripción</TableCell>
                  <TableCell>Monto Pagado</TableCell>
               </TableRow>
            </TableHead>
            <TableBody>
               {paymentHistory.map((payment, index) => (
                  <TableRow key={index}>
                     <TableCell>{payment.date}</TableCell>
                     <TableCell>{payment.student}</TableCell>
                     <TableCell>{payment.description}</TableCell>
                     <TableCell>{`$${payment.amount.toFixed(2)}`}</TableCell>
                  </TableRow>
               ))}
            </TableBody>
         </Table>
      </Paper>
   );
};

export default HistoryExample;
