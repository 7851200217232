import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   loginDisplay: 0,
   loginDisplayLastClicked: 0,
   lang: "es",
   notRobot: false,
   toggle: true,
   cookieConsent: true,
   darkMode: false,
   user: {
      token: "",
      email: "",
      name: "",
      photoUrl: "",
      createdAt: 1651367411061,
      bio: `NA `,
      lastLoginAt: 1661467511061,
      lastSignInTime: "1661467511061",
      ul: 99, // user level
      demo: true, // demo user
   },
   value: 0,
   dim: { wi: 100, he: 100 },
};

export const sessionSlice = createSlice({
   name: "session",
   initialState,
   reducers: {
      setSession: (state, action) => {
         // Update the state with the payload, spreading current state properties
         return { ...state, ...action.payload };
      },
      setToggleLang: (state) => {
         state.lang = state.lang === "en" ? "es" : "en";
      },
      clearSession: () => initialState,
   },
});

export const { setSession, clearSession, setToggleLang } = sessionSlice.actions;

export const selectSession = (state) => state.session.value;

export default sessionSlice.reducer;
