import React from "react";
import { Select, MenuItem, FormControl } from "@mui/material";
import { setToggleLang } from "../features/session/sessionSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";

const Lang = () => {
   const dispatch = useAppDispatch();
   const session = useAppSelector((state) => state.session);
   const EN = session.lang === "en" ? true : false;
   const handleChange = () => dispatch(setToggleLang());

   return (
      <FormControl
         variant='outlined'
         size='small'
         style={{
            position: "absolute",
            top: 0,
            right: 0,
            margin: "10px",
         }}
      >
         <Select value={EN ? "EN" : "ES"} onChange={handleChange}>
            <MenuItem value='EN'>EN</MenuItem>
            <MenuItem value='ES'>ES</MenuItem>
         </Select>
      </FormControl>
   );
};

export default Lang;
