import React, { useState, useEffect } from "react";
import {
   Paper,
   Grid,
   Typography,
   Select,
   MenuItem,
   Table,
   TableHead,
   TableRow,
   TableCell,
   TableBody,
   TextField,
   Radio,
   RadioGroup,
   FormControlLabel,
   Button,
} from "@mui/material";
import { SCHOOL_NAME, SCHOOL_LOGO } from "../components/constants";
import EmailPage from "./EmailPage";
import PayPage from "./PayPage";
import PageTemplate from "../components/PageTemplate";
import HistoryExample from "./HistoryExample";
import { useAppSelector } from "../app/hooks";

const activitiesList = {
   Pedro: [
      { description: "Mensualidad", price: 350 },
      { description: "Matrícula", price: 350 },
      { description: "Útiles Escolares", price: 500 },
      { description: "Clases de Fútbol", price: 25 },
   ],
   Sandra: [
      { description: "Mensualidad", price: 350 },
      { description: "Matrícula", price: 350 },
      { description: "Útiles Escolares", price: 500 },
      { description: "Robótica", price: 50 },
   ],
};

const paymentHistory = [
   {
      date: "3 de Agosto de 2024",
      student: "Sandra Pérez",
      description: "Mensualidad",
      amount: 375,
   },
   {
      date: "3 de Agosto de 2024",
      student: "Sandra Pérez",
      description: "Matrícula",
      amount: 500,
   },
   {
      date: "3 de Agosto de 2024",
      student: "Pedro Pérez",
      description: "Mensualidad",
      amount: 375,
   },
   {
      date: "5 de Septiembre de 2024",
      student: "Sandra Pérez",
      description: "Mensualidad",
      amount: 250,
   },
   {
      date: "5 de Septiembre de 2024",
      student: "Pedro Pérez",
      description: "Mensualidad",
      amount: 375,
   },
   {
      date: "15 de Septiembre de 2024",
      student: "Sandra Pérez",
      description: "Robótica",
      amount: 50,
   },
];

const StudentPayment = () => {
   const session = useAppSelector((state) => state.session);
   const EN = session.lang === "en" ? true : false;
   const [selectedOption, setSelectedOption] = useState("");
   const [activitiesData, setActivitiesData] = useState([]);
   const [total, setTotal] = useState(0);
   const [payPage, setPayPage] = useState(false);
   const [emailPage, setEmailPage] = useState(true);
   const [email, setEmail] = useState("");
   const [snackbarOpen, setSnackbarOpen] = useState(false);

   const handleClick = () => setPayPage(true);

   useEffect(() => {
      if (selectedOption && selectedOption !== "history") {
         initializeActivitiesData();
      } else {
         setActivitiesData([]);
         setTotal(0);
      }
   }, [selectedOption]);

   const initializeActivitiesData = () => {
      const initialData = activitiesList[selectedOption].map((activity) => ({
         ...activity,
         amount: 0, // Start total at zero
         selectedRadio: "partial", // Default to rightmost radio button
      }));
      setActivitiesData(initialData);
      setTotal(0); // Ensure total starts at 0
   };

   const handleSelectChange = (event) => {
      setSelectedOption(event.target.value);
   };

   const handleRadioChange = (index, value) => {
      setActivitiesData((prevData) => {
         const newData = [...prevData];
         if (value === "full") {
            newData[index].selectedRadio = "full";
            newData[index].amount = newData[index].price;
         } else {
            newData[index].selectedRadio = "partial";
            if (!newData[index].customAmount) {
               newData[index].amount = 0;
            } else {
               newData[index].amount =
                  parseFloat(newData[index].customAmount) || 0;
            }
         }
         calculateTotal(newData);
         return newData;
      });
   };

   const handleAmountChange = (index, value) => {
      setActivitiesData((prevData) => {
         const newData = [...prevData];
         newData[index].customAmount = value;
         newData[index].selectedRadio = "partial";
         newData[index].amount = parseFloat(value) || 0;
         calculateTotal(newData);
         return newData;
      });
   };

   const calculateTotal = (data) => {
      const newTotal = data.reduce((acc, activity) => acc + activity.amount, 0);
      setTotal(newTotal);
   };

   const handleSnackbarClose = () => setSnackbarOpen(false);

   return (
      <PageTemplate>
         {emailPage ? (
            <EmailPage
               setEmail={setEmail}
               email={email}
               snackbarOpen={snackbarOpen}
               handleSnackbarClose={handleSnackbarClose}
               setEmailPage={setEmailPage}
               setSnackbarOpen={setSnackbarOpen}
            />
         ) : payPage ? (
            <PayPage total={total} setPayPage={setPayPage} />
         ) : (
            <div
               style={{
                  padding: "20px",
                  margin: 5,
                  margin: "auto",
                  maxWidth: "600px",
               }}
            >
               <Typography variant='h2' align='center'>
                  {SCHOOL_NAME}
               </Typography>
               <Grid container spacing={2}>
                  <Grid
                     item
                     xs={12}
                     sm={6}
                     style={{
                        display: "flex",
                        flexDirection:
                           window.innerWidth < 550 ? "column" : "row",
                        alignItems:
                           window.innerWidth < 550 ? "center" : "flex-end",
                        justifyContent:
                           window.innerWidth < 550 ? "center" : "flex-start",
                     }}
                  >
                     <Typography variant='h6'>
                        {EN ? "Guardian" : "Acudiente"}: Andrés Pérez
                     </Typography>
                  </Grid>
                  <Grid
                     item
                     xs={12}
                     sm={6}
                     style={{
                        display: "flex",
                        justifyContent:
                           window.innerWidth < 550 ? "center" : "flex-end",
                     }}
                  >
                     <img
                        src={"./img/" + SCHOOL_LOGO}
                        alt='Logo'
                        style={{ width: "200px" }}
                     />
                  </Grid>
               </Grid>

               <div
                  style={{
                     display: "flex",
                     justifyContent: "center",
                     marginTop: "20px",
                     marginBottom: "20px",
                  }}
               >
                  <Select
                     value={selectedOption}
                     onChange={handleSelectChange}
                     displayEmpty
                     style={{ maxWidth: "600px", width: "100%" }}
                  >
                     <MenuItem value='' disabled>
                        {EN ? "Select ..." : "Seleccione..."}
                     </MenuItem>
                     <MenuItem value='Pedro'>Pedro Pérez</MenuItem>
                     <MenuItem value='Sandra'>Sandra Pérez</MenuItem>
                     <MenuItem value='history'>Historial de Pagos</MenuItem>
                  </Select>
               </div>

               {!selectedOption ? (
                  <Typography variant='h5' align='center'>
                     {EN
                        ? "Welcome Andrés please make a selection"
                        : "Bienvenido Andrés, por favor seleccione una opción para ontinuar"}
                  </Typography>
               ) : selectedOption && selectedOption !== "history" ? (
                  <div
                     style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "20px",
                     }}
                  >
                     <Paper
                        elevation={2}
                        style={{
                           padding: "20px",
                           maxWidth: "740px",
                           width: "100%",
                           boxSizing: "border-box",
                        }}
                     >
                        <Table>
                           <TableHead>
                              <TableRow className='header'>
                                 <TableCell>
                                    {EN ? "Description" : "Descripción"}
                                 </TableCell>
                                 <TableCell>
                                    <span style={{ marginRight: 50 }}>
                                       {EN ? "Full Amount" : "Monto Total"}
                                    </span>{" "}
                                    {EN ? "Amount to Pay" : "Monto a Pagar"}
                                 </TableCell>
                              </TableRow>
                           </TableHead>
                           <TableBody>
                              {activitiesData.map((activity, index) => (
                                 <TableRow key={index}>
                                    <TableCell>
                                       {activity.description}
                                    </TableCell>

                                    <TableCell>
                                       <RadioGroup
                                          row
                                          value={activity.selectedRadio}
                                          onChange={(e) =>
                                             handleRadioChange(
                                                index,
                                                e.target.value
                                             )
                                          }
                                       >
                                          <FormControlLabel
                                             control={
                                                <Radio className='left-radio-button' />
                                             }
                                             label={`$${activity.price.toFixed(
                                                2
                                             )}`}
                                             value='full'
                                             style={{
                                                width: 120,
                                             }}
                                          />
                                          <FormControlLabel
                                             control={<Radio />}
                                             value='partial'
                                             label={
                                                <TextField
                                                   type='number'
                                                   value={
                                                      activity.selectedRadio ===
                                                      "partial"
                                                         ? activity.customAmount ||
                                                           ""
                                                         : ""
                                                   }
                                                   onChange={(e) =>
                                                      handleAmountChange(
                                                         index,
                                                         e.target.value
                                                      )
                                                   }
                                                   onFocus={() =>
                                                      handleRadioChange(
                                                         index,
                                                         "partial"
                                                      )
                                                   }
                                                   InputProps={{
                                                      startAdornment: (
                                                         <span
                                                            style={{
                                                               marginRight:
                                                                  "6px",
                                                            }}
                                                         >
                                                            $
                                                         </span>
                                                      ),
                                                   }}
                                                   variant='standard'
                                                   size='small'
                                                />
                                             }
                                          />
                                       </RadioGroup>
                                    </TableCell>
                                 </TableRow>
                              ))}
                           </TableBody>
                        </Table>
                     </Paper>
                  </div>
               ) : (
                  <HistoryExample paymentHistory={paymentHistory} />
               )}

               {selectedOption === "history" && (
                  <Typography
                     variant='h6'
                     align='right'
                     style={{ marginTop: "20px" }}
                  >
                     TOTAL PAGADO: $
                     {paymentHistory
                        .reduce((acc, payment) => acc + payment.amount, 0)
                        .toFixed(2)}
                  </Typography>
               )}
               {selectedOption && selectedOption !== "history" && (
                  <Typography
                     variant='h6'
                     align='right'
                     style={{ marginTop: "20px" }}
                  >
                     {EN ? "Total Payment" : "Total a Pagar"}: $
                     {total.toFixed(2)}
                  </Typography>
               )}

               {selectedOption && selectedOption !== "history" && (
                  <Button
                     variant='contained'
                     color='primary'
                     style={{
                        float: "right",
                        marginTop: "20px",
                        marginBottom: "20px",
                     }}
                     onClick={() => handleClick()}
                  >
                     {EN ? "continue to payment" : "Continuar"} →
                  </Button>
               )}
            </div>
         )}
      </PageTemplate>
   );
};

export default StudentPayment;
