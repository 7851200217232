import React from "react";
import "../App.css";
const { useAppSelector } = require("../app/hooks");

const Footer = () => {
   const session = useAppSelector((state) => state.session);
   const EN = session.lang === "en" ? true : false;
   return (
      <div className='footer'>
         <br />
         <img src='./img/visa.png' alt='Visa' height='30px' />
         <img src='./img/mastercard.png' alt='Mastercard' height='33px' />
         <img
            src='./img/amex.png'
            alt='AMEX'
            height='30px'
            style={{ marginLeft: 10 }}
         />
         <div style={{ width: "100%" }} />
         <a
            href='https://paytiptap.com/en/terms-and-conditions.html'
            target='_blank'
            rel='noopener noreferrer'
            className='link'
         >
            {EN ? "Terms and Conditions" : "Condiciones de servicio"}
         </a>
         {" | "}
         <a
            href='https://paytiptap.com/en/privacy-policies.html'
            target='_blank'
            rel='noopener noreferrer'
            className='link'
         >
            {EN ? "Privacy Policy" : "Política de privacidad"}
         </a>
         <br />
         <span style={{ color: "#eee" }}> 0.8</span>
      </div>
   );
};

export default Footer;
