export const cleanUrl = (url) => {
   const patternsToRemove = [
      "https://",
      "http://",
      "localhost:3000",
      "paytiptap.com",
   ];

   let clearedUrl = url;

   patternsToRemove.forEach((pattern) => {
      clearedUrl = clearedUrl.replace(new RegExp(pattern, "g"), "");
   });

   return clearedUrl;
};
