import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import { get, set } from "idb-keyval";

// Load state from IndexedDB
const loadState = async () => {
   try {
      const storedState = await get("reduxState");
      return storedState !== undefined ? storedState : undefined;
   } catch (error) {
      console.error("Error loading state from IndexedDB:", error);
      return undefined;
   }
};

// Save state to IndexedDB
const saveState = async (state) => {
   try {
      await set("reduxState", state);
   } catch (error) {
      console.error("Error saving state to IndexedDB:", error);
   }
};

const initializeStore = async () => {
   const initialState = await loadState();

   const store = configureStore({
      reducer: rootReducer,
      preloadedState: initialState, // Initialize store with loaded state
   });

   // Save state to IndexedDB whenever it changes
   store.subscribe(() => {
      saveState(store.getState());
   });

   return store;
};

export const storePromise = initializeStore();

// Since this is JavaScript, we don't need to define types for `AppDispatch`, `RootState`, or `AppThunk`.
